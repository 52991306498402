<template>
    <div class="bg-light">
        <div class="container pt-4 pb-4">
            <vs-card class="">
                <div class="d-flex justify-content-between align-items-center" slot="header">
                    <h4>{{environment}}API Keys</h4>
                    <vs-button class="pl-4 pr-4" @click="generateNewKey">Generate new key</vs-button>
                </div>
                <vs-row class="mt-4 pb-4">
                    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="8" vs-lg="8">
                        <div class="d-flex pl-4 pr-4 pb-4">
                            <vs-input class="flex-fill mr-2" placeholder="" :label="publicKeyLabel" v-model="publicKey" disabled></vs-input>
                            <vs-button class="mt-4" type="border">Copy</vs-button>
                        </div>
                        <div class="d-flex flex-wrap pl-4 pr-4 pb-4">
                            <vs-input class="flex-fill mr-2" placeholder="" :label="secretKeyLabel" v-model="secretKey" disabled></vs-input>
                            <vs-button class="mt-4" type="border">Copy</vs-button>
                        </div>
                    </vs-col>
                    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="4" vs-lg="4">
                        
                    </vs-col>
                </vs-row>
            </vs-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'ApiKey',
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['user']),
        publicKeyLabel() {
            return (this.user && this.user.environment) ? (this.user.environment === 'live' ? 'Live Public Key' : 'Test Public Key') : '';
        },
        secretKeyLabel() {
            return (this.user && this.user.environment) ? (this.user.environment === 'live' ? 'Live Secret Key' : 'Test Secret Key') : '';
        },
        publicKey() {
            return (this.user && this.user.environment) ? (this.user.environment === 'live' ? this.user.live_public_key : this.user.test_public_key) : '';
        },
        secretKey() {
            return (this.user && this.user.environment) ? (this.user.environment === 'live' ? this.user.live_secret_key : this.user.test_secret_key) : '';
        },
        environment() {
            return this.user ? (this.user.environment.charAt(0).toUpperCase() + this.user.environment.slice(1)) + ' ' : '';
        }
    },
    methods: {
        ...mapActions(['saveWebhook']),
        generateNewKey() {
            
        }
    }
}
</script>
