<template>
    <div class="bg-light">
        <div class="container pt-4 pb-4">
            <vs-card class="">
                <div>
                    <div class="d-flex pt-4 pl-4 pr-4">
                        <div class="">
                            <h5 class="">Email Notifications</h5>
                        </div>
                    </div>
                    <div class="m-0 text-muted pl-4 pr-4 pt-4">
                        <p class="lead">Enter the email adress that you will use for receiving email notifications regarding payments, settlements, daily paymment reports, webhooks, etc.</p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center pt-1 pl-4 pr-4 pb-4">
                        <vs-input class="flex-fill mr-2" placeholder="Enter your email" v-model="email"></vs-input>
                        <vs-button>Save Changes</vs-button>
                    </div>
                </div>
            </vs-card>
            <vs-card class="mt-4">
                <div>
                    <div class="d-flex align-items-center pt-4 pl-4 pr-4">
                        <div class="">
                            <h5 class="">SMS Notifications</h5>
                        </div>
                        <div class="ml-3">
                            <vs-switch v-model="smsEnabled"/>
                        </div>
                    </div>
                    <div class="mt-2 text-muted pl-4 pr-4 pb-2">
                        <p class="lead">Receive notifications from Utupay via SMS on your number <b class="text-primary">{{user.user_phone_number}}</b></p>
                    </div>
                </div>
            </vs-card>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Notifications',
    data() {
        return {
            smsEnabled: true,
            email: ''
        }
    },
    created() {
        this.email = this.user ? this.user.user_email : '';
    },
    computed: {
        ...mapState(['user']),
    }
}
</script>
