<template>
    <div class="bg-light">
        <div class="container pt-4 pb-4">
            <vs-card class="">
                <div class="d-flex justify-content-between align-items-center" slot="header">
                    <h4>Setup Webhooks</h4>
                    <vs-button class="pl-4 pr-4" @click="saveDetails">Save</vs-button>
                </div>
                <vs-row class="mt-4 pb-4">
                    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="8" vs-lg="8">
                        <vs-input class="w-100" label="URL" placeholder="Enter your API endpoint" description-text="This endpoint will only receive payment hooks." v-model="webhookUrl"></vs-input>
                        <br>
                        <vs-input class="mt-4 w-100" label="Secret Hash" placeholder="Enter a secret hash" v-model="secretHash"></vs-input>
                    </vs-col>
                    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="4" vs-lg="4">
                        
                    </vs-col>
                </vs-row>
                <div class="d-flex justify-content-center align-items-center pb-4 mt-4 text-primary">
                    <a href="https://utupay.africa/docs/webhooks" target="_blank">
                        <h5 class="d-inline">Learn more about webhooks</h5>
                        <vs-icon class="ml-1" size="18px" icon="launch"></vs-icon>
                    </a>
                </div>
            </vs-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Webhook',
    data() {
        return {
            smsEnabled: true,
            webhookUrl: '',
            secretHash: ''
        }
    },
    created() {
        let webhooks = (this.user && this.user.webhooks) ? this.user.webhooks[0] : {};
        this.webhookUrl = webhooks ? webhooks.webhook_url : '';
        this.secretHash = webhooks ? webhooks.secret_hash : '';
    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
        ...mapActions(['saveWebhook']),
        saveDetails() {
            let payload = {
                webhook_url: this.webhookUrl || '',
                secret_hash: this.secretHash || ''
            };
            this.saveWebhook(payload);
        }
    }
}
</script>
