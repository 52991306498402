<template>
<div>
    <h3>Settings</h3>
    <vs-card class="mt-4 p-0 account">
        <div>
            <vs-tabs class="account p-0">
                <vs-tab label="Configuration" class="pl-0 pr-0 pb-0">
                    <Configuration/>
                </vs-tab>
                <vs-tab label="Webhook" class="pl-0 pr-0 pb-0">
                    <Webhook/>
                </vs-tab>
                <vs-tab label="API Keys" class="pl-0 pr-0 pb-0">
                    <ApiKey/>
                </vs-tab>
                <vs-tab label="Notifications" class="pl-0 pr-0 pb-0">
                    <notifications/>
                </vs-tab>
            </vs-tabs>
        </div>
    </vs-card>
</div>
</template>

<script>
import Configuration from './Configuration'
import Webhook from './Webhook'
import ApiKey from './ApiKey'
import Notifications from './Notifications.vue'

export default {
    name: 'Settings',
    components: { Configuration, Webhook, ApiKey, Notifications }
}
</script>