<template>
    <div class="bg-light">
        <div class="container pt-4 pb-4">
            <vs-card class="">
                <div class="d-flex justify-content-between align-items-center" slot="header">
                    <h4>Business Information</h4>
                    <vs-button class="pl-4 pr-4" @click="saveDetails">Save</vs-button>
                </div>
                <vs-row class="mt-4 pb-4">
                    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="8" vs-lg="8">
                        <vs-row>
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Business Name" v-model="businessName"></vs-input>
                            </vs-col>
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Business Phone Number" v-model="businessPhoneNumber"></vs-input>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mt-4">
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Business Email" v-model="businessEmail"></vs-input>
                            </vs-col>
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Business Address" v-model="businessAddress"></vs-input>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mt-4">
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Support Email" v-model="supportEmail"></vs-input>
                            </vs-col>
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Support Phone Number" v-model="supportPhoneNumber"></vs-input>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mt-4">
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Dispute Email" v-model="disputeEmail"></vs-input>
                            </vs-col>
                            <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="6" vs-lg="6">
                                <vs-input label="Chargeback Email" v-model="chargeBackEmail"></vs-input>
                            </vs-col>
                        </vs-row>
                    </vs-col>
                    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="4" vs-lg="4">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div class="d-flex flex-column">
                                <Avatar class="align-self-center" :src="businessLogo" backgroundColor="grey" :size="70" :username="businessName || 'utu pay'"/>
                                <h5 class="text-muted mt-1">Upload buiness logo</h5>
                                <vs-button class="mt-2">Upload Image</vs-button>
                            </div>
                        </div>
                    </vs-col>
                </vs-row>
            </vs-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Avatar from 'vue-avatar';

export default {
    name: 'Configuration',
    components: { Avatar },
    data() {
        return {
            businessName: '',
            businessPhoneNumber: '',
            businessEmail: '',
            businessAddress: '',
            supportEmail: '',
            supportPhoneNumber: '',
            disputeEmail: '',
            chargeBackEmail: '',
            businessLogo: ''
        }
    },
    created() {
        this.businessName = this.user ? this.user.business_name : '';
        this.businessPhoneNumber = this.user ? this.user.business_phone_number : '';
        this.businessEmail = this.user ? this.user.business_email : '';
        this.businessAddress = this.user ? this.user.business_address : '';
        this.supportEmail = this.user ? this.user.support_email : '';
        this.supportPhoneNumber = this.user ? this.user.support_phone_number : '';
        this.disputeEmail = this.user ? this.user.dispute_email : '';
        this.chargeBackEmail = this.user ? this.user.chargeback_email : '';
        this.businessLogo = this.user ? this.user.business_logo : '';
    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
        ...mapActions(['updateBusinessDetails']),
        saveDetails() {
            let payload = {
                business_name: this.businessName || '',
                business_phone_number: this.businessPhoneNumber || '',
                business_email: this.businessEmail || '',
                business_address: this.businessAddress || '',
                support_email: this.supportEmail || '',
                support_phone_number: this.supportPhoneNumber || '',
                dispute_email: this.disputeEmail || '',
                chargeback_email: this.chargeBackEmail || '',
                business_logo: this.businessLogo || ''
            };
            this.updateBusinessDetails(payload);
        }
    }
}
</script>
